import * as React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import colors from 'src/assets/styles/colors'
import distances from 'src/assets/styles/distances'
import typographySizes from 'src/assets/styles/typographySizes'
import Img from 'gatsby-image'
import mediaQuery from 'src/assets/styles/mediaQuery'
import { InView } from 'react-intersection-observer'
import Seo from 'src/components/global/Seo.js'

import MainWrapper from 'src/components/global/MainWrapper.js'
import Lines from 'src/components/global/lines/Lines'
import TextBarAnimationKontakt from 'src/components/global/TextBarAnimationKontakt.js'
import arrow from 'src/assets/svg/contact-arrow.svg'
import nbct from 'src/assets/svg/nbct-tag.svg'

import BarText from 'src/components/global/texts/BarText.js'
import Map from 'src/components/global/map/MapOne.js'

const StyledMain = styled.main`
  background-color: ${colors.mainColor};
  width: 100vw;

  /* overflow-y: hidden; */
  ${MainWrapper} {
    display: flex;
    position: relative;
    justify-content: space-between;

    @media (max-width: ${mediaQuery.tablet}) {
      /* flex-direction: column; */
      flex-wrap: wrap;
    }
  }
`

const SmallTitle = styled(BarText)`
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 50rem;
`

const MapCol = styled.div`
  width: 33.33%;
  height: calc(100vh - ${distances.mFromTop}rem);
  overflow: scroll;
  padding-top: ${distances.mFromTop}rem;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    height: auto;
    padding-top: ${distances.mFromTop + 50}rem;
    overflow: initial;
  }
`

const SocialCol = styled.div`
  width: 66.66%;

  padding-top: ${distances.mFromTop}rem;

  height: calc(100vh - ${distances.mFromTop}rem);

  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    height: auto;
    padding-top: 0;
    overflow: initial;
    margin-bottom: ${distances.mBottomPage / 2}rem;
    padding-bottom: 0;
  }
`

const LocalizationWrapper = styled.div`
  overflow: hidden;
  position: relative;
  margin-bottom: 20rem;
  :last-of-type {
    margin-bottom: 80rem;
    @media (max-width: ${mediaQuery.tablet}) {
      margin-bottom: 50rem;
    }
  }
`
const LocalizationName = styled.span`
  position: absolute;
  right: 20rem;
  top: -3rem;
  transform: rotate(-3deg);
  padding: 5rem 4rem 2rem 4rem;
  background-color: ${colors.white};
  font-size: ${typographySizes.s}rem;
  font-weight: 700;
  text-transform: uppercase;
`

const LocalizationBottom = styled.div`
  display: flex;
  margin: 20rem;
  align-items: flex-start;
  justify-content: space-between;
`

const LocalizationInfo = styled.p`
  font-size: ${typographySizes.s}rem;
  text-transform: uppercase;
  color: ${colors.black};
  margin-right: 20rem;
  a {
    font-size: ${typographySizes.s}rem;
    text-transform: uppercase;
    color: ${colors.black};
    @media (max-width: ${mediaQuery.tablet}) {
      padding: 7rem 0;
      display: inline-block;
    }
  }
`

const LocalizationStreet = styled.span`
  font-weight: 700;
  font-size: ${typographySizes.s}rem;
`

const SocialMedia = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const SocialTitle = styled.span`
  font-size: ${typographySizes.m}rem;
  font-weight: 700;
  display: block;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
`

const SocialSubTitle = styled.span`
  font-size: ${typographySizes.s}rem;
  display: block;
  margin-top: -10rem;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
`

const SocialPosition = styled(Link)`
  width: calc(50% - 40rem);
  margin-left: 20rem;
  margin-right: 20rem;
  text-transform: uppercase;
  margin-bottom: 30rem;
  :hover {
    ${SocialTitle} {
      transform: translateY(-15rem);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
    ${SocialSubTitle} {
      transform: translateY(-15rem);
      transition: all 0.5s 0.1s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 40rem);
  }
`

const SmallInfo = styled.div`
  margin-top: 80rem;
  margin-left: calc(50% + 20rem);

  display: flex;
  align-items: center;
  max-width: 260rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 20rem;
    margin-top: 50rem;
    max-width: 100%;
  }
`

const ImagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-top: 100rem;
  position: relative;

  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 60rem;
  }
`

const ImageOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.mainColor};

  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
`

const ImageWrapper = styled.div`
  position: relative;
  width: calc(50% - 80rem);
  margin-left: 40rem;
  margin-right: 40rem;
  margin-bottom: 70rem;
  transform: rotate(-3deg);
  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);

  transition: all 1s cubic-bezier(0.65, 0, 0.35, 1);

  &.show {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    transition: all 1s 0.2s cubic-bezier(0.65, 0, 0.35, 1);
  }
  :nth-of-type(odd) {
    transform: rotate(3deg);
    top: -150rem;
  }
  :nth-of-type(3n) {
    ${ImageOverlay} {
      display: none;
    }
  }
  :nth-of-type(3n - 1) {
    ${ImageOverlay} {
      mix-blend-mode: multiply;
    }
  }
  :nth-of-type(3n - 2) {
    ${ImageOverlay} {
      mix-blend-mode: screen;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-top: 20rem;
    :nth-of-type(odd) {
      margin-top: 20rem;
      top: 20rem;
    }
    :last-of-type {
      margin-bottom: 0;
    }
  }
`

const TextBarAnimationWrapper = styled.div`
  /* left: -20rem;
  position: relative; */
  pointer-events: none;

  @media (max-width: ${mediaQuery.tablet}) {
    position: absolute;
    height: 100%;
    pointer-events: none;
    width: 100vw;
    top: 0;
    left: -10rem;
  }
`

const Kontakt = ({ data }) => {
  return (
    <>
      <Seo
        title={
          data.strapiPageContact.SEO ? data.strapiPageContact.SEO.Title : null
        }
        description={
          data.strapiPageContact.SEO
            ? data.strapiPageContact.SEO.Description
            : null
        }
        image={
          data.strapiPageContact.SEO
            ? data.strapiPageContact.SEO.Img.localFile.publicURL
            : null
        }
      />
      <StyledMain>
        <MainWrapper>
          <Lines leftMargin leftCenter rightCenter rightMargin />
          <MapCol>
            <SmallTitle>
              {data.strapiPageContact.Title_Localizations}
            </SmallTitle>
            {data.allStrapiLocalizations.edges.map(localization => (
              <LocalizationWrapper>
                <Map localization={localization.node}></Map>
                <LocalizationName>{localization.node.Name}</LocalizationName>
                <LocalizationBottom>
                  <LocalizationInfo>
                    <LocalizationStreet>
                      {localization.node.Street} <br />
                    </LocalizationStreet>
                    {localization.node.Zip_code} <br />
                    {localization.node.City} <br /> <br />
                    <a href={`mailto:${localization.node.Email}`}>
                      {localization.node.Email}
                    </a>{' '}
                    <br />
                    <a href={`tel:${localization.node.Tel}`}>
                      {localization.node.Tel}
                    </a>
                  </LocalizationInfo>
                  <img src={arrow} />
                </LocalizationBottom>
              </LocalizationWrapper>
            ))}
          </MapCol>
          <SocialCol>
            <SmallTitle>{data.strapiPageContact.Title_Social_media}</SmallTitle>
            <SocialMedia>
              {data.strapiGeneral.Social_media.map(position => (
                <SocialPosition to={position.Link} target="_blank">
                  <SocialTitle> {position.Name} </SocialTitle>
                  <SocialSubTitle>
                    {position.Subtitle_for_Contact}
                  </SocialSubTitle>
                </SocialPosition>
              ))}
            </SocialMedia>
            <SmallInfo>
              <img src={nbct} />
              <BarText>{data.strapiPageContact.Small_Info}</BarText>
            </SmallInfo>

            <ImagesWrapper>
              {data.strapiPageContact.Bottom_Imgs.map(img => (
                <InView threshold={0} triggerOnce>
                  {({ inView, ref, entry }) => (
                    <ImageWrapper ref={ref} className={inView && 'show'}>
                      <Img fluid={img.localFile.childImageSharp.fluid} />
                      <ImageOverlay />
                    </ImageWrapper>
                  )}
                </InView>
              ))}
            </ImagesWrapper>
          </SocialCol>
          <TextBarAnimationWrapper>
            <TextBarAnimationKontakt
              mail={data.strapiGeneral.Email}
              tel={data.strapiGeneral.Tel}
            />
          </TextBarAnimationWrapper>
        </MainWrapper>
      </StyledMain>
    </>
  )
}

export const KontaktQuery = graphql`
  query KontaktQuery {
    strapiPageContact {
      SEO {
        Description
        Img {
          localFile {
            publicURL
          }
        }
        Title
      }
      Small_Info
      Title_Localizations
      Title_Social_media
      Bottom_Imgs {
        localFile {
          childImageSharp {
            fluid(
              quality: 100
              grayscale: true

              traceSVG: { background: "#000000", color: "#FF3D00" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
    allStrapiLocalizations {
      edges {
        node {
          Name
          lat
          lng
          City
          Email
          Street
          Tel
          Zip_code
        }
      }
    }
    strapiGeneral {
      Email
      Tel
      Social_media {
        Link
        Name
        Subtitle_for_Contact
      }
    }
  }
`

export default Kontakt
