import React, { useRef, useEffect } from 'react'

import styled from 'styled-components'
import gsap from 'gsap'

import colors from 'src/assets/styles/colors.js'
import typographySizes from 'src/assets/styles/typographySizes.js'
import mediaQuery from 'src/assets/styles/mediaQuery'
import Div100vh from 'react-div-100vh'

const OverflowWrapper = styled.div`
  overflow: hidden;
  width: calc(100% + 40rem);
  position: absolute;
  z-index: 10;
  bottom: 0rem;
  left: -20rem;
  padding: 40rem 0;
  @media (max-width: ${mediaQuery.tablet}) {
    top: 0;
    transform: translateY(0%);
    width: calc(100%);
    padding: 0rem 0;
    left: 0rem;
    position: sticky;
    pointer-events: auto;
  }
`
const StyledDiv100vh = styled(Div100vh)`
  position: relative;
  /* background-color: red; */
  /* background-color: blue; */
  pointer-events: none;
`
const RotateWrapper = styled.div`
  transform: rotate(-2deg);
  pointer-events: auto;
  position: absolute;
  bottom: 10rem;
  width: 100%;
  pointer-events: auto;
  @media (max-width: ${mediaQuery.tablet}) {
    transform: rotate(-3deg) translateY(-100%);
    bottom: 0rem;
  }
`

const ModuleWrapper = styled.span`
  width: calc(100%);
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  margin-top: 0rem;
`

const Module = styled.span`
  display: flex;
  align-items: center;

  a {
    color: ${colors.black};
    font-size: ${typographySizes.l}rem;
    background-color: ${colors.secColor};
    font-weight: 700;
    position: relative;
    top: 5rem;
    white-space: nowrap;

    display: inline-block;
    padding: 5rem 50rem 0 50rem;
  }
`

const TextBarAnimationKontakt = ({ tel, mail }) => {
  const wrapper = useRef(null)

  useEffect(() => {
    const movmentTimeline = gsap.timeline({
      repeat: -1,
    })
    const element = wrapper.current

    const bounds = element.children[0].getBoundingClientRect()
    const w = bounds.width

    movmentTimeline
      .set(element, { x: 0 })
      .to(element, { x: -w, duration: w * 0.01, ease: 'linear' })
  })

  return (
    <OverflowWrapper>
      <StyledDiv100vh>
        <RotateWrapper>
          <ModuleWrapper ref={wrapper}>
            {[...Array(10)].map((x, i) => (
              <Module key={i}>
                <a href={`mailto:${mail}`}>{mail}</a>
                <a href={`tel:${tel}`}>{tel}</a>
              </Module>
            ))}
          </ModuleWrapper>
        </RotateWrapper>
      </StyledDiv100vh>
    </OverflowWrapper>
  )
}

export default TextBarAnimationKontakt
